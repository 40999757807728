import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { User } from '@capturum/complete';
import { AppRoutes } from '@core/enums/routes.enum';
import { MenuItem as CompleteMenuItem } from 'primeng/api';
import { finalize, first } from 'rxjs/operators';
import { ListRendererApiService } from '@capturum/builders/list-renderer';
import { TenantService } from '@core/services/tenant.service';
import { Tenant } from '@core/models/tenant.model';
import { NgxPermissionsService } from 'ngx-permissions';

export interface MenuItem extends CompleteMenuItem {
  requiredPermission?: string;
}

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  public menuItems: MenuItem[];
  public tenantsItems: Tenant[];
  public user: User;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly listRendererApiService: ListRendererApiService,
    private readonly tenantService: TenantService,
    private readonly ngxPermissionService: NgxPermissionsService,
  ) {
    this.user = this.authService.getUser();
  }

  public ngOnInit(): void {
    if (this.ngxPermissionService.getPermission('tenant.manage')) {
      this.getTenantList();
    }

    this.menuItems = this.getMenuItems();
  }

  public getTenantList(): void {
    this.listRendererApiService.data('/builder/source/list_tenants', 'list_tenants').subscribe((response) => {
      return (this.tenantsItems = response.data);
    });
  }

  public executeCommand(item: MenuItem): void {
    if (item.command) {
      item.command(null);
    } else if (item.routerLink) {
      this.router.navigateByUrl(item.routerLink);
    }
  }

  public switchTenant(tenant: Tenant): void {
    this.tenantService.switchTenant(tenant.id).subscribe(() => {
      window.location.reload();
    });
  }

  private getMenuItems(): MenuItem[] {
    return [
      {
        label: 'link.profile',
        icon: 'fas fa-user-circle',
        routerLink: `/${AppRoutes.users}/${AppRoutes.manage}/${AppRoutes.profile}`,
      },
      {
        label: 'link.logout',
        icon: 'fas fa-sign-in-alt',
        command: () => {
          this.authService
            .logout()
            .pipe(
              first(),
              finalize(() => {
                this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
              }),
            )
            .subscribe();
        },
      },
    ];
  }
}
