import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SetReports } from '@core/store/report/report.actions';
import { ListRendererApiService } from '@capturum/builders/list-renderer';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class ReportsResolver {
  constructor(private readonly listRendererApiService: ListRendererApiService, private readonly store: Store) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this.listRendererApiService
      .data('/builder/source/list_manage_power_bi_reports', 'list_manage_power_bi_reports')
      .subscribe((response) => {
        if (response?.data?.length > 0) {
          this.store.dispatch(new SetReports(response.data));
        }
      });
  }
}
