import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { Store } from '@ngxs/store';
import { Report } from '@core/models/report.model';
import { LogoService } from '@core/services/logo.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '@core/enums/routes.enum';
import { MenuItem } from 'primeng/api';
import { ReportService } from '@core/services/report.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationBarComponent extends DestroyBase implements OnInit {
  public items: MenuItem[] = [];
  public innerWidth = window.innerWidth;
  public homePageUrl: string;
  public logoUrl$: Observable<string>;
  public isExpanded: boolean;

  public constructor(
    private readonly ngxPermissionService: NgxPermissionsService,
    private readonly store: Store,
    private readonly logoUrlService: LogoService,
    private readonly router: Router,
    private translateService: TranslateService,
    private reportService: ReportService,
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.innerWidth = window.innerWidth;
    this.isExpanded = this.innerWidth > 1280;
  }

  public ngOnInit(): void {
    this.isExpanded = this.innerWidth > 1280;
    this.logoUrl$ = this.logoUrlService.getLogoUrl();
    this.homePageUrl = AppRoutes.dashboard;

    this.router.navigate([this.homePageUrl]);
    this.getItems();
  }

  public getItems(): void {
    this.reportService
      .getReportList()
      .pipe(
        map((reports) => {
          return Object.entries(reports).map(([key, value]) => {
            const groupedReports = value as Report[];

            if (key === '') {
              return groupedReports.map((report) => {
                return {
                  label: report.name,
                  icon: 'far fa-file-chart-pie',
                  routerLink: `/report-view/${report.id}`,
                };
              });
            }

            return {
              label: value[0].group_name,
              icon: 'far fa-file-chart-pie',
              items: groupedReports.map((report) => {
                return {
                  label: report.name,
                  icon: 'far fa-file-chart-pie',
                  routerLink: `/report-view/${report.group_name}/${report.id}`,
                };
              }),
            };
          });
        }),
        map((mappedReports: any) => {
          return mappedReports.reduce((accumulator, currentValue) => {
            return accumulator.concat(currentValue);
          }, []);
        }),
      )
      .subscribe((items) => {
        this.items = [
          ...items,
          {
            label: this.translateService.instant('mdn.sidebar.acceptance-reports'),
            routerLink: AppRoutes.acceptanceReports,
            icon: 'fas fa-file-check',
            items: [],
            visible: !!this.ngxPermissionService.getPermission('report.portal.manage'),
          },
          {
            label: this.translateService.instant('mdn.sidebar.settings'),
            routerLink: AppRoutes.settings,
            icon: 'fas fa-cogs',
            items: [],
            visible: !(
              !this.ngxPermissionService.getPermission('user.manage') &&
              !this.ngxPermissionService.getPermission('user.manage.tenant')
            ),
          },
        ];
      });
  }

  public toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }
}
