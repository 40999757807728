import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiHttpService } from '@capturum/api';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { Theme } from '@core/models/theme.model';
import { LoginConfig, LoginConfigService } from '@capturum/login';
import { LogoService } from '@core/services/logo.service';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TenantResolver {
  constructor(
    private httpApiService: ApiHttpService,
    private themeService: ThemeService,
    private loginConfigService: LoginConfigService,
    private logoUrlService: LogoService,
    private titleService: Title,
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this.httpApiService.get('/theme').subscribe((theme: { data: Theme }) => {
      if (theme?.data) {
        this.setTheme(theme.data);
      }
    });
  }

  public setTheme(theme: Theme): void {
    this.themeService.setProps({
      [CSSVarNames.Primary]: theme.primary_color,
      [CSSVarNames.Secondary]: theme.secondary_color,
    });

    const loginConfig: Partial<LoginConfig> = {};

    if (theme?.favicon) {
      localStorage.setItem('faviconApp', theme.favicon);

      const favIcon: HTMLLinkElement = document.querySelector('#appIcon');

      favIcon.href = theme.favicon;
    }

    if (theme?.title) {
      this.titleService.setTitle(theme.title);
    }

    if (theme?.login_background_image) {
      loginConfig.backgroundImageUrl = theme.login_background_image;
    }

    if (theme?.logo) {
      loginConfig.logoImageUrl = theme.logo;
      this.logoUrlService.setLogoUrl(theme.logo);
    }

    if (theme?.title) {
      loginConfig.productName = theme.title;
    }

    this.loginConfigService.setConfig(loginConfig);
  }
}
