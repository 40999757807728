import { Action, State, StateContext } from '@ngxs/store';
import { ReportStatePrototype } from '@core/store/report/report-state-prototype.model';
import { Injectable } from '@angular/core';
import { SetReportId, SetReports } from '@core/store/report/report.actions';

@State<ReportStatePrototype>({
  name: 'report',
})
@Injectable()
export class ReportState {
  @Action(SetReportId)
  public setReportId(ctx: StateContext<ReportStatePrototype>, { reportId }: SetReportId): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      reportId,
    });
  }

  @Action(SetReports)
  public setReports(ctx: StateContext<ReportStatePrototype>, { reports }: SetReports): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      reports,
    });
  }
}
