import { Report } from '@core/models/report.model';

export class SetReportId {
  public static readonly type = '[REPORT] Set report id';

  constructor(public reportId: string) {}
}

export class SetReports {
  public static readonly type = '[REPORT] Set reports';

  constructor(public reports: Report[]) {}
}
