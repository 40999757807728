import { BUILD_TIMESTAMP } from '@environments/timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Postmus Experience Platform',
  production: true,
  hmr: false,
  name: 'Production',
  baseUrl: 'https://interior-union.k8s.emendis.nl/api',
  sentryUrl: 'https://ba4b614544c645239d2eec6439300cd5@o4504360297496576.ingest.sentry.io/4504377896599552',
  databaseName: 'emendis.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
  url: 'http://localhost:4200',
  idleDuration: 600, // 10min
  timeoutDuration: 60, // 1min
};
