import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ReportSelectors } from '@core/store/report/report.selectors';
import { takeUntil } from 'rxjs/operators';
import { DestroyBase } from '@capturum/shared';
import { HeaderService } from '@core/services/header.service';

@Component({
  selector: 'app-power-bi-report-view',
  templateUrl: './power-bi-report-view.component.html',
  styleUrls: ['./power-bi-report-view.component.scss'],
})
export class PowerBiReportViewComponent extends DestroyBase implements OnInit {
  public reportId: string;
  public reportPage: string;

  constructor(
    private readonly activeRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly headerConfigService: HeaderService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.activeRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const reports = this.store.selectSnapshot(ReportSelectors.getReports);

      reports?.forEach((report) => {
        if (report?.id === params.reportId) {
          this.reportPage = report.page_name;
          this.reportId = report.id;
        }
      });

      this.headerConfigService.setHeaderConfig({
        mainHeader: this.reportPage,
        backUrl: '',
      });
    });
  }
}
