import { Component, OnInit } from '@angular/core';
import { VersionCheckService } from '@capturum/complete';
import { environment } from '@environments/environment';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [VersionCheckService],
})
export class AppComponent implements OnInit {
  public confirmation: Confirmation;

  constructor(
    private readonly versionCheckService: VersionCheckService,
    private readonly themeService: ThemeService,
    private readonly translateService: TranslateService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  public ngOnInit(): void {
    this.fetchVersionChanging();
    this.setTheme();

    this.confirmationService.requireConfirmation$.subscribe((result) => {
      return (this.confirmation = result);
    });
  }

  private setTheme(): void {
    this.themeService.setProps({
      [CSSVarNames.Primary]: '#00939b',
      [CSSVarNames.Success]: '#4cd964',
      [CSSVarNames.Warning]: '#ff9500',
      [CSSVarNames.Error]: '#ff3b30',
      [CSSVarNames.Font]: 'Source Sans Pro',
      [CSSVarNames.DynamicFilterBorderRadius]: '16px',
      [CSSVarNames.InputHeight]: '33px',
    });
  }

  private fetchVersionChanging(): void {
    if (environment.name === 'Local') {
      return;
    }

    this.versionCheckService.initVersionCheck(`https://${window.location.host}/version.json`, 10000);

    this.versionCheckService.onUpdateVersion.subscribe(() => {
      window.location.reload();
    });
  }
}
