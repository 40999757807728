<div class="popup-content">
  <form [formGroup]="form">
    <cap-textarea
      class="mb-3"
      data-test="remark"
      formControlName="remark"
      [label]="'mdn.report.remark.label' | translate"
      [placeholder]="'mdn.report.remark.label' | translate">
    </cap-textarea>
  </form>
</div>

<div class="popup-actions">
  <cap-button styleClass="secondary mt-3" [label]="'button.cancel' | translate" (onClick)="onClose()"> </cap-button>

  <cap-button
    appPreventDoubleClick
    [label]="'mdn.button.reject' | translate"
    class="danger ms-2 mt-3"
    (clickEvent)="submit()">
  </cap-button>
</div>
