import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderConfig } from '@core/models/header-config.model';
import { HeaderService } from '@core/services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public headerConfig$: Observable<HeaderConfig>;

  constructor(private readonly headerConfigService: HeaderService) {}

  public ngOnInit(): void {
    this.headerConfig$ = this.headerConfigService.getHeaderConfig();
  }
}
