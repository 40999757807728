<div class="cap-sidebar" [ngClass]="{ 'cap-sidebar--collapsed': !isExpanded }">
  <div class="cap-sidebar__brand">
    <div class="brand-wrapper" [routerLink]="homePageUrl">
      <img alt="logo" [src]="(logoUrl$ | async) || './assets/images/emendis-complete-logo.png'" />
    </div>
  </div>

  <div class="card flex justify-content-center">
    <p-panelMenu [model]="items"></p-panelMenu>
  </div>

  <div class="cap-sidebar__button" (click)="toggleExpanded()">
    <ng-container *ngIf="isExpanded; else collapsedButton">
      <i class="fas fa-chevron-left"></i>
    </ng-container>

    <ng-template #collapsedButton>
      <i class="fas fa-chevron-right"></i>
    </ng-template>
  </div>
</div>
