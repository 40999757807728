import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormModule } from '@capturum/formly';
import { SharedModule } from '@shared/shared.module';
import { ListRendererService } from '@capturum/builders/list-renderer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { DoubleClickDirective } from '@shared/directives/double-click.directive';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ReportService } from '@core/services/report.service';

interface RejectFormGroup {
  remark: FormControl<string>;
}
@Component({
  selector: 'app-reject-report',
  standalone: true,
  imports: [CommonModule, FormModule, SharedModule, CapturumTextareaModule, DoubleClickDirective, ReactiveFormsModule],
  templateUrl: './reject-report.component.html',
  styleUrls: ['./reject-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RejectReportComponent implements OnInit {
  public reportId: string;
  public form: FormGroup<RejectFormGroup>;

  constructor(
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private listRendererService: ListRendererService,
    private reportService: ReportService,
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.reportId = this.config.data?.item?.id;
  }

  public onClose(): void {
    this.dialogRef.close(true);
  }

  public submit(): void {
    this.reportService.rejectReport(this.reportId, this.form.get('remark').value).subscribe(() => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('mdn.report.reject-report.success'),
      );

      this.listRendererService.refreshTable();
      this.onClose();
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      remark: ['', Validators.required],
    });
  }
}
