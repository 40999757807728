import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  private _logoUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public setLogoUrl(logoUrl: string): void {
    this._logoUrl.next(logoUrl);
  }

  public getLogoUrl(): Observable<string> {
    return this._logoUrl.asObservable();
  }
}
