import { Selector } from '@ngxs/store';
import { ReportState } from '@core/store/report/report.state';
import { ReportStatePrototype } from '@core/store/report/report-state-prototype.model';
import { Report } from '@core/models/report.model';

export class ReportSelectors {
  @Selector([ReportState])
  public static getReportId(state: ReportStatePrototype): string {
    return state.reportId;
  }

  @Selector([ReportState])
  public static getReports(state: ReportStatePrototype): Report[] {
    return state.reports;
  }
}
