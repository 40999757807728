import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationLoader } from '@capturum/complete';
import { CoreModule } from '@core/core.module';
import { ConfirmationService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInjector } from './app-injector.service';
import { CapturumToastModule } from '@capturum/ui/toast';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumMissingTranslationHandler } from '@capturum/shared';
import { NgxsModule } from '@ngxs/store';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { CapturumPowerBIModule } from '@capturum/powerbi';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PowerBiReportViewComponent } from './features/power-bi-report-view/power-bi-report-view.component';
import { ReportState } from '@core/store/report/report.state';
import { RejectReportComponent } from './features/acceptance-reports/components/reject-report/reject-report.component';
import { ReportFormComponent } from './features/reports/pages/report-form/report-form.component';
import { GroupReportFormComponent } from './features/group-reports/pages/group-report-form/group-report-form.component';

@NgModule({
  declarations: [AppComponent, PowerBiReportViewComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CapturumMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    CoreModule,
    CapturumToastModule,
    CapturumListRendererModule.forRoot({}),
    CapturumFormRendererModule.forRoot({
      translationKeyPrefix: '',
    }),
    ConfirmDialogModule,
    CapturumButtonModule,
    CapturumBuilderCoreModule.forRoot({
      baseUrl: 'builders',
      components: {
        list_portal_reports_reject: RejectReportComponent,
        list_reports_edit: ReportFormComponent,
        list_group_reports_edit: GroupReportFormComponent,
      },
    }),
    NgxsModule.forRoot([ReportState]),
    CapturumPowerBIModule,
  ],
  providers: [ConfirmationService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
