import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, finalize, first, switchMap, take, takeUntil } from 'rxjs/operators';
import { CapturumDialogService, LocalStorageService } from '@capturum/ui/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, IdleUserService } from '@capturum/auth';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { AppRoutes } from '@core/enums/routes.enum';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
})
export class BasicLayoutComponent implements OnInit, OnDestroy {
  public favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  public idleToastKey = 'idle-toast';
  public expiryKey = 'user.idle.expiry';
  public timeoutDurationInMilliseconds = environment.timeoutDuration * 1000;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private idleExpiration: number;
  private loggedOutSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly translateService: TranslateService,
    private router: Router,
    private authService: AuthService,
    private idleUserService: IdleUserService,
    private messageService: MessageService,
    private storageService: LocalStorageService,
    private dialogService: DialogService,
    private capturumDialogService: CapturumDialogService,
  ) {}

  public ngOnInit(): void {
    const faviconApp = localStorage.getItem('faviconApp');

    if (faviconApp) {
      this.favIcon.href = faviconApp;
    }

    this.idleExpiration = this.storageService.getItem(this.expiryKey);

    this.idleUserService.watchIdle();
    this.listenIdleTimeout();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public idleToastClose(): void {
    this.messageService.clear();
    this.idleUserService.resetTimer();
    this.listenIdleTimeout();
  }

  private listenIdleTimeout(): void {
    this.idleUserService
      .getIdleStart()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.idleExpiration = this.storageService.getItem(this.expiryKey);
      });

    this.idleUserService
      .getIdleTimeoutWarning()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.messageService.add({
          key: this.idleToastKey,
          sticky: true,
          severity: 'warn',
          summary: this.translateService.instant('mdn.idle.toast.message.summary.label'),
          detail: this.translateService.instant('mdn.idle.toast.message.details.label'),
        });
      });

    this.idleUserService
      .getIdleTimeout()
      .pipe(
        take(1),
        takeUntil(this.loggedOutSubject),
        switchMap(() => {
          return this.logout();
        }),
      )
      .subscribe();
  }

  private logout(): Observable<boolean> {
    this.messageService.clear();

    this.dialogService.dialogComponentRefMap.forEach((dialog) => {
      dialog.destroy();
    });
    this.capturumDialogService.dialogComponentRefMap.forEach((capturumDialog) => {
      capturumDialog.destroy();
    });
    this.idleUserService.stopWatching();

    return this.authService.logout().pipe(
      catchError(() => {
        return of(null);
      }),
      first(),
      finalize(() => {
        this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
      }),
    );
  }
}
