import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { Report, ReportList } from '@core/models/report.model';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends ApiService<Report> {
  protected endpoint = 'report';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public rejectReport(reportId: string, remark: string): Observable<Report> {
    return this.apiHttp.post(`/${this.endpoint}/${reportId}/reject`, { remark });
  }

  public getReportList(): Observable<ReportList> {
    return this.apiHttp.get(`/${this.endpoint}/list`);
  }
}
