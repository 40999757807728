<nav class="navbar navbar-static-top header d-flex" role="navigation">
  <div id="headerContainer" class="container container-header">
    <div class="row align-items-center w-100">
      <div class="col d-flex justify-content-between align-items-center menu">
        <div *ngIf="(headerConfig$ | async)?.backUrl as backUrl">
          <a class="backUrl" [routerLink]="backUrl">
            <span class="d-flex flex-row justify-content-center align-items-center">
              <i class="fa fa-chevron-left me-2 pt-1"></i>
              {{ 'button.back-to-overview' | translate }}
            </span>
          </a>
        </div>

        <span class="main-header">{{ (headerConfig$ | async)?.mainHeader }}</span>
        <app-user-menu></app-user-menu>
      </div>
    </div>
  </div>
</nav>
