import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { Tenant } from '@core/models/tenant.model';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends ApiService<Tenant> {
  protected endpoint = 'tenant-switch';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public switchTenant(tenantId: string): Observable<Tenant> {
    return this.apiHttp.post(`/${this.endpoint}`, {
      tenant_id: tenantId,
    });
  }
}
