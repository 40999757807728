<div class="d-flex flex-row">
  <app-navigation-bar></app-navigation-bar>

  <div id="right">
    <div id="headerWrapper">
      <app-header></app-header>
    </div>

    <div id="wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<p-toast
  position="top-right"
  styleClass="cap-toast idle-toast"
  [baseZIndex]="5000"
  [key]="idleToastKey"
  (onClose)="idleToastClose()">
  <ng-template let-message pTemplate="message">
    <div class="d-flex">
      <i class="toast-icon d-flex justify-content-center flex-column fas fa-hourglass-half"></i>

      <div class="toast-content">
        <h3 class="toast-summary">{{ message.summary }}</h3>

        <span class="toast-detail" [innerHTML]="message.detail"></span>

        <div class="toast-button">
          <cap-button
            styleClass="primary"
            [label]="'mdn.idle.toast.button.stay_online' | translate"
            (onClick)="idleToastClose()">
          </cap-button>
        </div>

        <div class="toast-progressbar" [style.animation-duration]="timeoutDurationInMilliseconds + 'ms'"></div>
      </div>
    </div>
  </ng-template>
</p-toast>
