import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent, LoginComponent, LoginLayoutComponent, ResetPasswordComponent } from '@capturum/login';
import { CompleteResolver, IndexedDbGuard, PublicTranslationResolver } from '@capturum/complete';
import { AuthGuard } from '@capturum/auth';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { BasicLayoutComponent } from '@core/components/basic-layout/basic-layout.component';
import { CapturumPowerBIClientsManageComponent } from '@capturum/powerbi/pages';
import { PowerBiReportViewComponent } from './features/power-bi-report-view/power-bi-report-view.component';
import { ReportsResolver } from '@core/resolvers/reports.resolver';
import { HeaderConfigResolver } from '@core/resolvers/header-config.resolver';
import { TenantResolver } from '@core/resolvers/tenant.resolver';
import { AppRoutes } from '@core/enums/routes.enum';

const routes: Routes = [
  {
    path: 'auth',
    component: LoginLayoutComponent,
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
      theme: TenantResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/auth/login',
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { permissions: {} },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { permissions: {} },
      },
      {
        path: 'password/reset/:token',
        component: ResetPasswordComponent,
        data: { permissions: {} },
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
        data: { permissions: {} },
      },
      {
        path: 'activate/:token/:email',
        component: ResetPasswordComponent,
        data: { permissions: {} },
      },
    ],
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard],
    resolve: {
      complete: CompleteResolver,
      reports: ReportsResolver,
    },
    children: [
      {
        path: '',
        component: BasicLayoutComponent,
        resolve: {
          theme: TenantResolver,
        },
        children: [
          {
            path: '',
            redirectTo: AppRoutes.settings,
            pathMatch: 'full',
          },
          {
            path: AppRoutes.dashboard,
            loadChildren: () => {
              return import('./features/dashboard/dashboard.module').then((m) => {
                return m.DashboardModule;
              });
            },
            resolve: {
              headerConfig: HeaderConfigResolver,
            },
            data: {
              headerConfig: {
                mainHeader: '',
                backUrl: '',
              },
            },
          },
          {
            path: 'report-view/:reportId',
            component: PowerBiReportViewComponent,
          },
          {
            path: 'report-view/:groupName/:reportId',
            component: PowerBiReportViewComponent,
          },
          {
            path: AppRoutes.settings,
            loadChildren: () => {
              return import('./features/config/config.module').then((m) => {
                return m.ConfigModule;
              });
            },
            resolve: {
              headerConfig: HeaderConfigResolver,
            },
            data: {
              headerConfig: {
                mainHeader: '',
                backUrl: '',
              },
              permissions: {
                only: ['user.manage', 'user.manage.tenant'],
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.tags,
            loadChildren: () => {
              return import('./features/tags/tags.module').then((m) => {
                return m.TagsModule;
              });
            },
            resolve: {
              headerConfig: HeaderConfigResolver,
            },
            data: {
              headerConfig: {
                mainHeader: 'mdn.tags.label',
                backUrl: '/settings',
              },
            },
          },
          {
            path: AppRoutes.tenantsTheme,
            loadChildren: () => {
              return import('./features/tenants-theme/tenants-theme.module').then((m) => {
                return m.TenantsThemeModule;
              });
            },
          },
          {
            path: AppRoutes.tenants,
            loadChildren: () => {
              return import('./features/tenant/tenant.module').then((m) => {
                return m.TenantModule;
              });
            },
          },
          {
            path: AppRoutes.roles,
            loadChildren: () => {
              return import('./features/role/role.module').then((m) => {
                return m.RoleModule;
              });
            },
            data: {
              permissions: {
                only: ['role.manage', 'role.manage.tenant'],
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.users,
            loadChildren: () => {
              return import('./features/user/user.module').then((m) => {
                return m.UserModule;
              });
            },
            resolve: {
              headerConfig: HeaderConfigResolver,
            },
            data: {
              headerConfig: {
                mainHeader: 'config.manage.users',
                backUrl: '/settings',
              },
              permissions: {
                only: ['user.manage', 'user.manage.tenant'],
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.reports,
            resolve: {
              headerConfig: HeaderConfigResolver,
            },
            loadChildren: () => {
              return import('./features/reports/routes').then((m) => {
                return m.default;
              });
            },
            data: {
              headerConfig: {
                mainHeader: 'mdn.power-bi.reports.label',
                backUrl: AppRoutes.settings,
              },
              permissions: {
                only: 'power-bi.manage',
                redirectTo: '/',
              },
            },
          },
          {
            path: 'power-bi/clients',
            component: CapturumPowerBIClientsManageComponent,
            resolve: {
              headerConfig: HeaderConfigResolver,
            },
            data: {
              headerConfig: {
                mainHeader: 'mdn.power-bi.clients.label',
                backUrl: '/settings',
              },
              permissions: {
                only: 'power-bi.manage',
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.groupReports,
            resolve: {
              headerConfig: HeaderConfigResolver,
            },
            loadChildren: () => {
              return import('./features/group-reports/routes').then((m) => {
                return m.default;
              });
            },
            data: {
              headerConfig: {
                mainHeader: 'mdn.power-bi.group-reports.label',
                backUrl: AppRoutes.settings,
              },
              permissions: {
                only: 'power-bi.manage',
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.builders,
            loadChildren: () => {
              return import('@capturum/builders/pages').then((m) => {
                return m.CapturumBuilderPagesModule;
              });
            },
            resolve: {
              headerConfig: HeaderConfigResolver,
            },
            data: {
              headerConfig: {
                mainHeader: 'mdn.builders.label',
                backUrl: '/settings',
              },
            },
          },
          {
            path: AppRoutes.baseData,
            loadChildren: () => {
              return import('./features/base-data/base-data.module').then((m) => {
                return m.BaseDataModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'base-data.manage',
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.acceptanceReports,
            loadChildren: () => {
              return import('./features/acceptance-reports/routes').then((m) => {
                return m.default;
              });
            },
            resolve: {
              headerConfig: HeaderConfigResolver,
            },
            data: {
              headerConfig: {
                mainHeader: 'mdn.sidebar.acceptance-reports',
                backUrl: '',
              },
              permissions: {
                only: ['report.portal.manage'],
                redirectTo: '/',
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'settings',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
