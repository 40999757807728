{
  "name": "complete-frontend-mdn",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:dev:local": "ng serve --c=local --open",
    "start:dev:test": "ng serve --c=test --open",
    "start:dev:acc": "ng serve --c=acc --open",
    "build": "ng build",
    "build:prod": "ng build --configuration production && npm run post-build",
    "test": "ng test --code-coverage",
    "test:ci": "ng test --no-watch --no-progress --browsers=ChromeHeadlessNoSandbox --karma-config=karma-pipeline.conf.js",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "lint:skip-warnings": "ng lint --quiet",
    "prettier": "npx prettier src/app --check",
    "prettier:fix": "npm run prettier -- --write",
    "format": "npm run prettier:fix && ng lint --fix",
    "e2e": "ng e2e",
    "cypress": "concurrently \"ng serve\" \"cypress open\"",
    "dockerserve": "ng serve --proxy-config proxy.conf.json --host 0.0.0.0 --disable-host-check",
    "stylelint": "stylelint '**/*.scss'",
    "post-build": "node ./build/post-build.js"
  },
  "pre-commit": [
    "lint",
    "stylelint"
  ],
  "private": true,
  "dependencies": {
    "@angular/animations": "^16.2.12",
    "@angular/cdk": "^16.2.12",
    "@angular/common": "^16.2.12",
    "@angular/compiler": "^16.2.12",
    "@angular/core": "^16.2.12",
    "@angular/forms": "^16.2.12",
    "@angular/platform-browser": "^16.2.12",
    "@angular/platform-browser-dynamic": "^16.2.12",
    "@angular/router": "^16.2.12",
    "@capturum/api": "^16.0.1",
    "@capturum/auth": "^16.0.1",
    "@capturum/builders": "^16.0.6",
    "@capturum/complete": "^16.0.4",
    "@capturum/formly": "^16.0.4",
    "@capturum/linting": "^16.0.0",
    "@capturum/login": "^16.0.4",
    "@capturum/powerbi": "^16.0.6-rc.0",
    "@capturum/shared": "^16.0.4",
    "@capturum/ui": "^16.0.5",
    "@fortawesome/fontawesome-pro": "^5.11.2",
    "@ngx-formly/core": "^6.2.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@ngxs/storage-plugin": "^3.8.1",
    "@ngxs/store": "^3.8.1",
    "@sentry/angular-ivy": "^7.47.0",
    "bootstrap": "^5.1.3",
    "jwt-decode": "^3.1.2",
    "ngx-permissions": "^14.1.0",
    "primeicons": "^6.0.1",
    "primeng": "^16.5.0",
    "quill": "^1.3.7",
    "rxjs": "^7.5.7",
    "tslib": "^2.5.0",
    "uuid": "^9.0.0",
    "zone.js": "^0.13.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^16.2.10",
    "@angular-eslint/builder": "16.3.1",
    "@angular-eslint/eslint-plugin": "16.3.1",
    "@angular-eslint/eslint-plugin-template": "16.3.1",
    "@angular-eslint/schematics": "^16.2.10",
    "@angular-eslint/template-parser": "16.3.1",
    "@angular/cli": "^16.2.10",
    "@angular/compiler-cli": "^16.2.12",
    "@angularclass/hmr": "^2.1.3",
    "@commitlint/cli": "^12.1.4",
    "@ngxs/devtools-plugin": "^3.8.1",
    "@types/jasmine": "^3.6.0",
    "@types/jasminewd2": "^2.0.3",
    "@types/node": "^12.20.50",
    "@typescript-eslint/eslint-plugin": "5.17.0",
    "@typescript-eslint/parser": "5.17.0",
    "concurrently": "^5.3.0",
    "eslint": "^8.37.0",
    "husky": "^4.3.8",
    "jasmine-core": "^4.6.0",
    "jasmine-spec-reporter": "^5.0.0",
    "karma": "^6.3.19",
    "karma-chrome-launcher": "^3.1.1",
    "karma-coverage": "^2.2.0",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "pre-commit": "^1.2.2",
    "protractor": "^7.0.0",
    "puppeteer": "^10.4.0",
    "stylelint": "^14.14.0",
    "ts-node": "^8.3.0",
    "tslint": "^6.1.0",
    "typescript": "~4.9.5"
  },
  "husky": {
    "hooks": {
      "commit-msg": "commitlint -E HUSKY_GIT_PARAMS",
      "pre-commit": "npm run lint && npm run stylelint"
    }
  }
}
